.trip-title{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size:32px;
  color: black;
}
.trip-text{
  font-family: "Segoe UI", sans-serif;
  font-size:24px;
  color: black;
}
.price{
  padding: 0;
  margin: 0;
  margin-left: 10px;
  font-family: "Segoe UI Semibold",sans-serif;
  color: black;
  font-size: 24px;
}
