.text-beach{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size: 32px;
  color: black;
}
.text-beach-about{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size: 16px;
  color: black;
}

