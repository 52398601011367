.HelloPicture{

  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
}
.HelloOpacity{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}
.services{
  background-color: rgba(12,12,12,0.65);
  width: 100%;
  color: white;
  size: 1.2rem;
  font-family: "Segoe UI", sans-serif;
}
.welcome-text{
  font-size: 48px;
  font-family: "Segoe UI", sans-serif;
  color: white;
  margin: 2px 0;
}
.welcome-text-big{
  font-size: 80px;
  font-family: "Segoe UI", sans-serif;
  color: white;
  margin: 2px 0;
}
