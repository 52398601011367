.Image{
  width: 100%;
  height: 50vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
}
.ImageOverlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}
.culture-text{
  font-family: "Segoe UI", sans-serif;
  color: white;
  font-size: 116px;
}

@media only screen and (max-width: 780px) {
  .culture-text{
    font-size: 80px;
  }
}
@media only screen and (max-width: 460px) {
  .culture-text{
    font-size: 48px;
  }
}
