.footer{
  padding: 120px;
  background-color: #000747;
  color: white;
  font-family: "Segoe UI",sans-serif;
  font-size: 1.2rem;
}
.dekic2003{
  text-decoration: none;
  border-bottom: 2px white solid;
  color: white;
  font-weight: bold;
}
.dekic2003:hover{
  text-decoration: none;
  border-bottom: 2px #a1a1a1 solid;
  color: #a1a1a1;
  font-weight: bold;
}
.dekic2003:link{
  text-decoration: none;
  border-bottom: 2px white solid;
  color: white;
  font-weight: bold;
}
