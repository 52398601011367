.color-nav{
  background-color: #000747;
}
.nav-link{
  font-size: 1.5rem;
  font-family: "Segoe UI Semibold", sans-serif;
  color: white!important;
  border-bottom: 1px #000747 solid;
}
.nav-link:hover{
  font-size: 1.5rem;
  font-family: "Segoe UI Semibold", sans-serif;
  color: white!important;
  border-bottom: 1px white solid;
}
