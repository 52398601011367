.title-of-event{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size:24px;
  color: black;
}
.where-event{
  font-family: "Segoe UI", sans-serif;
  font-size:24px;
  color: black;
}
.when-event{
  font-family: "Segoe UI Semilight", sans-serif;
  font-size:24px;
  color: black;
}
.events-title{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size:32px;
  color: black;
  width: 100%;
  margin: 0;
  padding: 0;
}
.bg-event{
  background-color: #E7E7EC;
}
.exp-btn {
  display: flex;
  margin-top: 10%;
  cursor: pointer;
  all: unset;
  font-family: 'Segoe UI Semibold',sans-serif;
  font-weight: 600;
  color: white;
  padding: 10px;
  font-size: 1.6rem;
  text-transform: none;
  padding-left: 50px;
  text-decoration: none;
  padding-right: 50px;
  border-radius: 30px;
  background-color: #000747;
}

.exp-btn:link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.exp-btn:hover {
  color: white;
  cursor: pointer;
}
