.card-distance{
  box-shadow: -1px -1px 32px 0px rgba(0,0,0,0.55);
  -webkit-box-shadow: -1px -1px 32px 0px rgba(0,0,0,0.55);
  -moz-box-shadow: -1px -1px 32px 0px rgba(0,0,0,0.55);
  padding: 2rem 4rem;
  width: 80%;
}
.distance-text{
  text-align: center;
  font-size: 72px;
  font-weight: normal;
  font-family: "Segoe UI", sans-serif;
  color: black;

}
.distance-dest{
  font-size: 1rem;
  font-family: "Segoe UI Semilight", sans-serif;
  color: black;

}
.distance-far{
  font-size: 1.2rem;
  font-family: "Segoe UI Semibold", sans-serif;
  color: black;

}
.distance-type{
  font-size: 1.2rem;
  font-family: "Segoe UI Semibold", sans-serif;
  color: black;
}
@media screen and (max-width: 1000px){
  .card-distance{
    width: 100%;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 600px){
  .distance-text{
    text-align: center;
    font-size: 48px;
    font-weight: normal;
    font-family: "Segoe UI", sans-serif;
    color: black;

  }
}
