.city{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size: 48px;
  color: black;
}
.about-city{
  font-family: "Segoe UI ", sans-serif;
  font-size: 24px;
  color: black;
}
