.park{
  font-family: "Segoe UI", sans-serif;
  font-size:24px;
  color: black;
}
.park-Name{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size:32px;
  color: black;
}
.park-text{
  font-family: "Segoe UI Semibold", sans-serif;
  font-size:16px;
  color: black;
}
