.DayPicker {
  display: inline-block;
  font-size: 2rem;
}
@media screen and (max-width:1400px ) {
  .DayPicker {
    font-size: 1.5rem;
  }
}
@media screen and (max-width:1005px ) {
  .DayPicker {
    font-size: 1.2rem;
  }
}
@media screen and (max-width:800px ) {
  .DayPicker {
    font-size: 1rem;
  }
}
.rooms{
  margin: 0;
  font-family: "Segoe UI Semibold", sans-serif;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  border-bottom: 2px white solid;

}
.rooms:hover{
  margin: 0;
  font-family: "Segoe UI Semibold", sans-serif;
  border-bottom: 2px #000747 solid;
  font-size: 1.5rem;
}
.reservation-on{
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Segoe UI Semibold", sans-serif;
}
.reservation-note{
  font-size: 1rem;
  font-weight: normal;
  font-family: "Segoe UI Semibold", sans-serif;
}
.cream{
  border-bottom: 2px #e3cdaa solid;
  color: white;
  background-color: #e3cdaa;
}
.red{
  border-bottom: 2px #ff0000 solid;
  color: white;
  background-color: #ff0000;
}
.gray{
  border-bottom: 2px #5b5b5b solid;
  color: white;
  background-color: #5b5b5b;
}
.orange{
  border-bottom: 2px #ff7400 solid;
  color: white;
  background-color: #ff7400;
}
.bag{
  border-bottom: 2px #028cc4 solid;
  color: white;
  background-color: #0069c0;
}
